.TemporaryStaffingLists-subTitle {
  font-weight: 600;
  display: inline;
}

.TemporaryStaffingLists-subPara {
  display: inline;
  width: 90%;
}

.lists p {
  width: 90%;
}

@media screen and (max-width: 850px) {
  .itContainer .itContentContainer .itLeftContainer h2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 600px) {
  .itContainer .itContentContainer .itLeftContainer h2 {
    font-size: 18px;
  }
}
