.headerCtn {
  width: 100%;
}

.sticky-header-show {
  width: 100%;
  z-index: 1;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #ffffff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.nav-items li a.active {
  border-bottom: 1px solid black; /* Adjust this to your desired underline style */
}

.nav-items li a:hover {
  transition: 0.2s ease-in-out;
  font-size: 13.3px;
  font-weight: 800;
}

.sticky-header-show header {
  width: 58%;
  margin: 0 auto;
}

.sticky-header-hide {
  position: relative;
}

.sticky-header-show header > ul {
  padding: 1% 0;
}

.headerCtn header a {
  font-size: 13px;
  text-transform: uppercase;
  color: #000;
}

.headerCtn.sticky-header-hide header ul {
  padding: 4% 0;
}
.headerCtn.sticky-header-show header ul {
  padding: 1.5% 0;
}

/* .headerCtn header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;

} */

.headerCtn header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  margin-top: 2%;
}

.headerCtn header ul a {
  font-family: "Lato", sans-serif;
}

.headerCtn .ShowNav {
  display: block;
}

.headerCtn .HideNav {
  display: none;
}

.headerCtn .HideNav.visibleNav {
  width: 58%;
  margin: 0 auto;
  display: block;
}

.headerCtn nav > ul {
  width: 85%;
  padding: 1.5% 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.headerLogoUl {
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.headerCtn nav > ul > li > a {
  font-size: 13px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
}

.techSierraSmallLogo {
  height: 50px;
  width: 110px;
}

.sticky-header-hide .scionLogo > a > img {
  /* width: 250px;
  height: 135px; */
  filter: contrast(80%);
  height: 100px;
  width: 180px;
}

.sticky-header-show .scionLogo > a > img {
  filter: contrast(80%);
}

.nav-items {
  position: relative;
  transition: 0.3s ease-in-out;
}

.services-subMenu {
  padding: 3% 2%;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #2f2f2f;
  transition: 120s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.services-subMenu.about {
  left: -10%;
}
.services-subMenu.apply {
  left: 70%;
}
.services-subMenu.hire {
  left: 77%;
}

.services-subMenu.temporary,
.services-subMenu.permenrnt {
  width: 100%;
  left: 5%;
  flex-direction: unset;
  flex-wrap: wrap;
}

.services-subMenu.temporary > li,
.services-subMenu.permenrnt > li {
  flex-basis: 33.33%;
}

.services-subMenu li:not(:last-child) {
  margin-bottom: 10px;
}
.services-subMenu li a {
  color: #666;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
}

.services-subMenu.clicked {
  display: none;
}

.services-subMenu li a:hover {
  color: #f2f2f2;
}

.techSierraSmallLogo {
  /* height: 135px;
  width: 250px; */
  height: 70px;
  width: 130px;
  margin: 5%;
}

.show-menu {
  display: flex;
  align-items: center;
}

.show-menu .logoContainer {
  flex-basis: 20%;
}

.show-menu .menuContainer {
  flex-basis: 80%;
}

.navbar-brand img {
  height: 80px;
  width: 140px;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.navbar-nav li {
  text-transform: uppercase;
  font-weight: 600;
}

.logo-container {
  width: 20%;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link.active {
  text-decoration: underline;
  margin-bottom: 2%;
}

/* @media screen and (max-width: 850px) {
  .headerCtn nav > ul > li > a {
    font-size: 12px;
  }
  .headerCtn nav > ul {
    width: 95%;
  }

  .techSierraSmallLogo {
    margin-top: 20px;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px auto;
  }

  .visibleNav {
    transition: opacity 1s ease-in-out;
  }

  .visibleNav li {
    transition: transform 0.5s ease-in-out;
  }

  .mobile-menu-toggle {
    display: block;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .show-menu {
    transition: transform 0.5s ease-in-out;
  }

  .show-menu .visibleNav {
    display: block;
    transition: transform 0.5s ease-in-out;
  }

  .hide-menu {
    display: block;
  }

  .nav-items {
    flex-direction: column;
    align-items: center;
  }

  .nav-items li {
    text-align: center;
  }

  .nav-items li a {
    padding: 10px;
    display: block;
  }

  .services-subMenu {
    position: static;
    display: block;
    width: 100%;
  }

  .services-subMenu li {
    text-align: center;
  }
} */

@media screen and (max-width: 850px) {
}

.nav-item {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

/* @media screen and (max-width: 1950px) { */
.services-subMenu.apply {
  left: 68%;
}
/* } */
.services-subMenu.apply {
  top: 66%;
}

@media screen and (max-width: 1750px) {
  .services-subMenu.apply {
    top: 68%;
  }
}

@media screen and (max-width: 1350px) {
  .services-subMenu.apply {
    top: 70%;
  }
}

@media screen and (max-width: 993px) {
  .logoContainer header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 5% 0px 5%;
  }

  /* .mobile-menu-toggle {
    position: fixed;
    margin-left: 80%;
    margin-top: 5%;
  } */

  .menuContainer {
    position: absolute;
    width: 100%;
    top: 15%;
    background-color: #ffffff;
    z-index: 1;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .logo-container {
    width: 50%;
  }

  .collapse {
    width: 50%;
  }
  .navbar-collapse {
    width: 50%;
  }

  .services-subMenu {
    position: static;
    display: block;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
  }

  .services-subMenu li {
    text-align: center;
  }

  .nav-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
}
