@media screen and (max-width: 850px) {
  .temporaryStuffingParaSection h2 {
    font-size: 24px;
  }
  .temporaryStuffingParaSection p {
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .temporaryStuffingParaSection h2 {
    font-size: 22px;
  }
  .temporaryStuffingParaSection p {
    font-size: 12px;
  }
}
