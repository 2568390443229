@media screen and (max-width: 850px) {
  .button-container .primary-button {
    font-size: 10px;
  }

  .button-container .employer_cta_button {
    font-size: 10px;
  }
}

.employer_cta_button:hover {
  background-color: #06a9ca;
  color: #ffffff;
}
