/* Footer Top */
.footer_top_holder {
  background-color: #2f2f2f;
  width: 100%;
  padding: 30px 0;
}

.top-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.top-wrapper .left-div img {
  margin: 30% 0;
}

.top-wrapper .middle-div {
  text-align: center;
  color: white;
  font-family: Lato, sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-wrapper .middle-div p .phoneNumber {
  color: #ffffff;
}

.top-wrapper .middle-div p {
  color: #ffffff;
  margin-bottom: 20px;
}

.top-wrapper .middle-div p:hover {
  color: #06a9ca;
}

.top-wrapper .middle-div p a:hover {
  color: #06a9ca;
}

.top-wrapper .middle-div p link p {
  color: #ffffff;
}

.top-wrapper .middle-div .sitemap {
  margin-top: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #06a9ca;
  height: 80px;
  width: 80px;
}

.social-icons div {
  border: 2px solid #06a9ca;
  border-radius: 50%;
  padding: 12px;
  margin: 0 12px;
  height: 60px;
  width: 62px;
  transition: 0.1s ease-in-out;
}

.social-icons div:hover {
  border: 2px solid #06a9ca;
  border-radius: 50%;
  padding: 15px;
  margin: 0 12px;
  height: 65px;
  width: 66px;
}

.social-icons div a {
  color: #06a9ca;
}

.top-wrapper .right-div {
  margin-top: 6%;
}

.right-div img {
  width: 120px;
  padding: 12px;
}

.right-div > div > img {
  width: 300px;
  align-items: center;
  margin-left: 15%;
}

/* Footer Bottom */

.footer_bottom_holder {
  display: flex;
  justify-content: center;
  background-color: #1b1b1b;
  padding: 10px 0 50px;
  color: #444;
}
