.searchItJobs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchItJobs .searchItJobsContainer {
  display: flex;
  margin-bottom: 100px;
}

.searchItJobs .searchItJobsContainer .searchItJobsLeft {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  margin-top: 80px;
}

.searchItJobs .searchItJobsContainer .searchItJobsRight {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  margin-top: 80px;
  align-items: center;
}

.searchItJobs .searchItJobsContainer .searchItJobsRight .rightBoxContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.searchItJobsDropdown {
  width: 95%;
}

.custom-select {
  height: 55px;
  width: 100%;
  margin: 10px 0;
  border: 2px solid #777;
}

.custom-select select {
  height: 50px;
  width: 100%;
  padding-left: 10px;
  border: none;
}

.searchItJobsDropdown input {
  width: 100%;
  border: 0;
  outline: 0;
  height: 50px;
  padding-left: 13px;
}

.searchItJobsDropdown input:active {
  width: 90%;
  border: none;
  padding: 20px 0px 0px 20px;
}

.searchBtn {
  height: 50px;
  width: 100%;
  margin: 10px 0;
  border: 2px solid #777;
  font-weight: bold;
}

.searchItJobRightBox {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 45%;
  margin: 1% 1% 1% 1%;
  position: relative;
  min-height: 356px;
  padding: 13px 36px;
  color: #000;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.searchItJobRightBox:hover {
  background-color: #f3f2f2;
}

.searchItJobRightBox h3 {
  font-size: 22px;
  margin-top: 10px;
  color: #06a9ca;
}

.searchItJobRightBox p {
  margin-top: 10px;
  line-height: 30px;
}

.searchItJobRightBox button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 30px;
  background-color: #06a9ca;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
}

.searchItJobRightBox button:hover {
  background-color: #0391ae;
  color: #ffffff;
}

.pagination button {
  padding: 10px;
  margin: 50px 10px 0px 0px;
}

.pagination button .selected {
  background-color: #06a9ca;
  color: #ffffff;
}

.searchItJobsLeft .checkBox {
  margin-top: 15px;
}

.searchItJobsLeft .checkBox label {
  margin-left: 5px;
}

.leftCheckBoxContainer h3 {
  font-weight: bold;
  margin: 10px 0px 10px;
}

.leftCheckBoxContainer .checkbtn {
  padding: 10px;
  margin: 5px 10px 0px 0px;
  cursor: pointer;
  background-color: #06a9ca;
  color: #ffffff;
  border: none;
}

.leftCheckBoxContainer .searchBtn {
  padding: 10px;
  width: 90%;
  margin: 30px 10px 0px 0px;
  cursor: pointer;
  background-color: #06a9ca;
  color: #ffffff;
  border: none;
}

.checkbox {
  margin-top: 15px;
}

.page-link:hover,
.page-link.selected {
  background-color: #f0f0f0; /* Change this to your desired hover effect color */
  /* Add any other styling you want for hover or selected state */
}

.current-cell {
  /* Style for the current cell */
  /* font-weight: bold; */
}

@media screen and (max-width: 1150px) {
  .searchItJobRightBox {
    width: 75%;
    min-height: 300px;
    padding: 13px 15px;
  }

  .searchItJobRightBox h3 {
    font-size: 22px;
  }

  .searchItJobRightBox p {
    font-size: 15px;
    line-height: 25px;
  }
}

@media screen and (max-width: 850px) {
  .searchItJobRightBox {
    width: 75%;
    min-height: 300px;
    padding: 13px 15px;
  }

  .searchItJobRightBox h3 {
    font-size: 22px;
  }

  .searchItJobRightBox p {
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (max-width: 700px) {
  .searchItJobRightBox {
    width: 75%;
    min-height: 300px;
    padding: 13px 15px;
  }

  .searchItJobRightBox h3 {
    font-size: 22px;
  }

  .searchItJobRightBox p {
    font-size: 14px;
    line-height: 22px;
  }

  .searchItJobsDropdown input {
    width: 100%;
    border: 0;
    outline: 0;
    padding-left: 13px
  }

  .checkbox {
    margin-top: 15px;
    display: flex;
    gap: 5%;
  }

  .searchItJobs .searchItJobsContainer {
    display: flex;
    margin-bottom: 100px;
    flex-direction: column;
  }
}
