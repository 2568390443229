.EmpowerCtn {
  padding: 113px 0 101px 0;
  background-color: #2f2f2f;
  font-family: "Lato", sans-serif;
}

.EmpowerCtn h2 {
  width: 95%;
  padding: 0 0 78px 0;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.EmpowerCtn h2::selection {
  color: white;
  background-color: #06a9ca;
}

.EmpowerCtn .MainCtn {
  display: flex;
  justify-content: space-between;
}

.EmpowerCtn .MainCtn li {
  width: 23%;
}

.EmpowerCtn .EmpowerTxt {
  height: 220px;
  margin-top: 10px;
  padding: 25px;
  background-color: #06a9ca;
  overflow: hidden;
}

.EmpowerCtn .EmpowerTxt h3 {
  color: #000;
  font-weight: 600;
}

.EmpowerCtn .EmpowerTxt {
  color: #444;
  font-size: 17px;
  line-height: 27px;
}

@media screen and (max-width: 1050px) {
  .EmpowerCtn .EmpowerTxt {
    height: 250px;
    padding: 10%;
  }

  .EmpowerCtn .EmpowerTxt h3 {
    font-size: 15px;
    line-height: 20px;
  }

  .EmpowerCtn .EmpowerTxt p {
    font-size: 13px;
    line-height: 15px;
  }
}

@media screen and (max-width: 700px) {
  .EmpowerCtn h2 {
    font-size: 20px;
  }

  .EmpowerCtn .MainCtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .EmpowerCtn .MainCtn li {
    width: 85%;
  }

  .EmpowerCtn .EmpowerTxt {
    color: #444;
    font-size: 17px;
    line-height: 27px;
    height: 230px;
    padding: 15px;
  }

  .EmpowerCtn .EmpowerTxt h3 {
    font-size: 16px;
    line-height: 25px;
  }

  .EmpowerCtn .EmpowerTxt p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 500px) {
  .EmpowerCtn .EmpowerTxt {
    color: #444;
    font-size: 17px;
    line-height: 27px;
    height: 230px;
    padding: 15px;
  }

  .EmpowerCtn .EmpowerTxt h3 {
    font-size: 20px;
    line-height: 22px;
  }

  .EmpowerCtn .EmpowerTxt p {
    font-size: 16px;
    line-height: 28px;
  }
}
