.temporaryStuffingHeading h1 {
  color: #06a9ca;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 60px 0px 40px 0px;
}

.temporaryStuffingHeading h3 {
  color: #000;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

@media screen and (max-width: 850px) {
  .temporaryStuffingHeading h1 {
    font-size: 35px;
  }

  .temporaryStuffingHeading h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .temporaryStuffingHeading h1 {
    font-size: 28px;
  }

  .temporaryStuffingHeading h3 {
    font-size: 16px;
  }
}
