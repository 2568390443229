.mission-section-btm-container {
  margin: 130px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mission-section-btm-links {
  display: flex;
  flex-direction: column;
}

.mission-section-btm-links li a {
  width: 100%;
  height: 90px;
  line-height: 90px;
  font-size: 20px;
  padding: 0;
  text-align: center;
  color: #06a9ca;
  text-transform: uppercase;
}

.it-section-btm-links {
  width: 90%;
}

.it-section-btm-links > li:hover {
  background-color: #ffffff;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 850px) {
  .section-title {
    font-size: 40px;
  }
  .mission-statement {
    font-size: 16px;
  }
  .subsection-title {
    font-size: 20px;
  }
  .subsection-content {
    font-size: 15px;
    line-height: 22px;
  }
  .value-title {
    font-size: 15px;
  }

  .value-description {
    font-size: 15px;
    line-height: 22px;
  }
  .primary-button {
    font-size: 20px;
  }

  .employer_cta_button {
    font-size: 15px;
  }
  .section-main-title {
    font-size: 25px;
  }
  .mission-section-btm-links li a {
    height: 60px;
    font-size: 18px;
  }
  .it-section-btm-links li a {
    height: 60px;
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .mission-section-btm-container {
    padding-bottom: 5%;
  }

  .section-title {
    font-size: 35px;
  }
  .mission-statement {
    font-size: 14px;
  }
  .subsection-title {
    font-size: 18px;
  }
  .subsection-content {
    font-size: 12px;
    line-height: 22px;
  }
  .value-title {
    font-size: 12px;
  }

  .value-description {
    font-size: 12px;
    line-height: 22px;
  }
  .primary-button {
    font-size: 18px;
  }

  .employer_cta_button {
    font-size: 12px;
  }
  .section-main-title {
    font-size: 22px;
  }
  .mission-section-btm-links li a {
    height: 30px;
    font-size: 16px;
  }
  .it-section-btm-links li a {
    height: 30px;
    font-size: 16px;
  }
  .it-section-btm-links li a {
    width: 100%;
    height: 30px;
    line-height: 90px;
    font-size: 14px;
    padding: 0;
    text-align: center;
    color: #06a9ca;
    text-transform: uppercase;
  }
}
