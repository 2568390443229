.belowContentSection {
  height: 180px;
  background-color: #ffffff;
  border: 1px solid black;
  align-items: center;
}

.belowContentSection .belowContentGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.belowContentSection h4 {
  color: #06a9ca;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 20px 0 10px 0;
  text-align: center;
}

.belowContentSection ul {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  justify-content: space-between;
  align-items: center;
}

.belowContentSection ul li {
  font-size: 19px;
  line-height: 28px;
}
