.ai-btm-ctn .button-container {
  display: none;
}

.ai-job-list li {
  line-height: 1.5;
  padding-left: 5%;
  display: block;
}

.ai-btm-ctn .itLeftContainer > p {
  padding-bottom: 0 !important;
}

.ai-btm-para {
  color: #444;
  font-size: 17px;
  line-height: 27px;
}

.ai-ctn .button-container .primary-button {
  width: 30%;
}

.min-500 {
  display: none;
}

.max-500 {
  display: block;
}

@media screen and (max-width: 850px) {
  .ai-btm-para {
    font-size: 15px;
    line-height: 27px;
  }
  .ai-job-list li {
    font-size: 15px;
  }
  .itContainer .itContentContainer .itLeftContainer p {
    font-size: 15px;
    line-height: 25px;
  }

  .ai-job-list {
    margin-top: 5%;
  }

  .ai-btm-para {
    margin-top: 5%;
  }
}

@media screen and (max-width: 600px) {
  .ai-btm-para {
    font-size: 15px;
    line-height: 27px;
  }
  .ai-job-list li {
    font-size: 15px;
  }
  .itContainer .itContentContainer .itLeftContainer p {
    font-size: 15px;
    line-height: 25px;
  }

  .max-500 {
    display: none;
  }

  .min-500 {
    display: block;
  }

  .itImageContentContainer {
    padding: 0% !important;
  }
  .ai-job-list {
    margin-top: 5%;
  }

  .ai-btm-para {
    margin-top: 5%;
  }
}
