.textBoxContainer {
  /* width: 30%; */
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 3% 0;
  margin: 0 auto;
  /* align-items: center; */
  /* overflow: hidden; */
}
.textBoxContainer .textBoxHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #06a9ca;
  font-size: 2.5em;
  /* align-items: center; */
  padding: 30px 0 40px 0;
  text-decoration: underline #06a9ca 2px;
  text-underline-offset: 10px;
  text-align: center;
}

.textBoxContainer .textBoxParagraph {
  background-color: rgba(255, 255, 255, 0.1);
}
.textMain {
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8);
}
.textBoxContainer .textBoxParagraph .textBoxPara {
  margin-bottom: 10px;
  color: #444;
  font-size: 17px;
  line-height: 27px;
}

.textBoxContainer .textBoxBtn {
  color: #06a9ca;
  font-size: 13px;
  height: 39px;
  border: 2px solid #06a9ca;
  margin-top: 15px;
}

.textBoxContainer .textBoxBtn:hover {
  background-color: #06a9ca;
  color: #ffffff;
  transition: 0.5s;
  font-size: 13px;
  height: 39px;
}

@media screen and (max-width: 1050px) {
  .textBoxContainer {
    background-size: 100% 100% !important;
    width: 100% !important;
  }

  .homePagePara2 {
    width: 60%;
    margin-left: 40%;
  }
}

@media screen and (max-width: 600px) {
  .textBoxContainer {
    background-size: 100% 100% !important;
    width: 100% !important;
  }

  .textMain {
    margin-left: 0% !important;
    width: 100% !important;
  }

  .textBoxContainer .textBoxParagraph .textBoxPara {
    font-size: 12px;
    line-height: 25px;
    margin-left: 0px;
  }
}
